import { defineComponent, computed } from 'vue';
import { Button } from '@/components/UI';
import TeamView from '@/components/Teams/TeamView';
import useMySchedule from '@/compositions/useMySchedule';
import store from '@/store';
import { sleep } from '@/testing/Timing';
import '../../components/Dashboard/Dashboard.scss';
export default defineComponent({
    name: 'MyBeatOut',
    components: {
        TeamView,
        Button
    },
    setup() {
        const team = computed(() => {
            var _a;
            const teams = Object.values(store.state.teams.active);
            if (teams.length > 0)
                return (_a = teams[0]) !== null && _a !== void 0 ? _a : null;
            return null;
        });
        return {
            ...useMySchedule(store.state.location.locationId, team),
            team
        };
    },
    data: () => ({
        pin: null
    }),
    computed: {
        background() {
            return {
                backgroundImage: 'url(' + require('@/assets/bg-dashboard.png') + ')'
            };
        },
        showStartButton() {
            return false; // this.currentRoomId !== null // TODO: implement team.room_id null check
        },
        teamDuration() {
            var _a, _b, _c;
            if (((_a = this.team) === null || _a === void 0 ? void 0 : _a.duration) === null)
                return 'Onbekend';
            const duration = (_c = (_b = this.team) === null || _b === void 0 ? void 0 : _b.duration) !== null && _c !== void 0 ? _c : 0;
            const minutes = Math.floor(duration / 60);
            const seconds = duration % 60;
            return `${minutes} min ${seconds} sec`;
        }
    },
    async mounted() {
        var _a;
        const pin = (_a = this.$route.query.pin) !== null && _a !== void 0 ? _a : null;
        if (typeof pin === 'string')
            this.pin = Number.parseInt(pin);
        await this.getTeam();
    },
    methods: {
        async getTeam() {
            if (this.pin !== null) {
                await this.$store.direct.dispatch.teams.getTeamFromPin(this.pin);
            }
            await sleep(15000);
            this.getTeam();
        },
        async startRoom() {
            if (this.currentRoomId === null) {
                console.error('no next room selected');
                return;
            }
            if (this.team === null) {
                console.error('no team selected');
                return;
            }
            const rfid = this.team.members.map(member => member.rfid).find(rfid => rfid !== null);
            if (rfid === undefined) {
                console.error('no rfid found');
                return;
            }
            await store.dispatch.rooms.setRFID({ roomid: this.currentRoomId, rfid: 'start', value: rfid });
        }
    }
});
